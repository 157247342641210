<template>
  <ax-form-builder
    :formBuilder="formBuilder"
    ref="formBuilder"
  >
  <template slot="file">
      <file-upload
        ref="file"
        class="file"
        fileType="SYS_STAFF"
        :reportId="this.reportId"
        :taskDefinitionKey="this.$route.query.taskDefinitionKey!==undefined?'PROCESS_SUBMIT':this.$route.query.taskDefinitionKey"
      >
      </file-upload>
      </template>
      </ax-form-builder>
</template>

<script>
import { setAttribute, isEmpty } from '@/common/tools'
import moment from 'moment'
import api from './api'
import fileUpload from '@/components/file-upload/index.vue'
export default {
  name: 'maintain',
  components: {
    fileUpload
  },
  props: ['currentTable'],
  inject: ['changeValue', 'getList'],
  data () {
    return {
      deptData: [],
      watchShow: false,
      formBuilder: {
        formConfig: {
          labelWidth: 80,
          hideRequiredMark: false,
          labelAlign: 'right',
          layout: 'horizontal',
          colon: true,
          modalWidth: 1000
        },
        formValues: {
          inputNumber_ef63210466da666bb6818e698ae6aa2: 4
        },
        childrenNodes: [
          {
            type: 'axNativeTable',
            isLeaf: false,
            icon: 'icon-kapian',
            key: 'axNativeTable_bed1fef33d31e30f43874d3f087b979',
            label: '表格布局',
            model: 'axNativeTable_bed1fef33d31e30f43874d3f087b979',
            options: {
              small: false,
              bordered: true,
              bright: false,
              width: '100%'
            },
            childrenNodes: [
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_d948a935ea5c5e810e8ed8e34faf8fe',
                        label: '姓名',
                        model: 'userName',
                        options: {
                          disabled: true,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: true,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      },
                      {
                        type: 'hidden',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_d948a935ea5c5e810e8ed8e34faf8fe',
                        label: '',
                        model: 'id',
                        options: {
                          disabled: true,
                          size: 'default',
                          hidden: true,
                          allowClear: true,
                          width: '1%'
                        },
                        formItem: {},
                        rules: []
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'select',
                        isLeaf: true,
                        icon: 'icon-xialaxuanze',
                        key: 'select_5af95cad4dd4d467e49f314bfa9c47a',
                        label: '性别',
                        model: 'sex',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: true,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          dataSourceType: 'staticData',
                          dataSource: [
                            {
                              label: '男',
                              value: '1',
                              disabled: false
                            },
                            {
                              label: '女',
                              value: '0',
                              disabled: false
                            }
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: true,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'datePicker',
                        isLeaf: true,
                        icon: 'icon-riqixuanze',
                        key: 'datePicker_537ee27a77914791178ff37506399fb',
                        label: '生日',
                        model: 'birthday',
                        options: {
                          allowClear: true,
                          disabled: true,
                          placeholder: '请选择',
                          size: 'default',
                          inputReadOnly: false,
                          format: 'YYYY-MM-DD',
                          showTime: false,
                          showToday: true,
                          width: '100%',
                          disabledDate: this.disableDate
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'inputNumber',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_c5b6181dddbd4d8c4da2dcd08404894',
                        label: '身份证',
                        model: 'identityCard',
                        options: {
                          disabled: true,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空'
                          },
                          {
                            validator: this.checkIdCard
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'inputNumber',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_d3d6c13da4f24a2d5a8eb6a03dde2e6',
                        label: '年龄',
                        model: 'age',
                        options: {
                          disabled: true,
                          size: 'default',
                          allowClear: true,
                          max: 120,
                          width: '100%',
                          placeholder: '根据生日自动计算'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_4349f432f5f3ff74af8550fdeebfa0e',
                        label: '电子邮件',
                        model: 'email',
                        options: {
                          disabled: true,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          },
                          {
                            validator: this.checkEmail
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'inputNumber',
                        isLeaf: true,
                        icon: 'icon-shuzishuru',
                        key: 'inputNumber_07ae5f366955f600268e50685b27701',
                        label: '电话',
                        model: 'phone',
                        options: {
                          disabled: true,
                          decimalSeparator: '.',
                          size: 'default',
                          step: 1,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: true,
                            message: '不能为空'
                          },
                          {
                            validator: this.checkPhone
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'inputNumber',
                        isLeaf: true,
                        icon: 'icon-shuzishuru',
                        key: 'inputNumber_07ae5f366955f600268e50685b27701',
                        label: '短号',
                        model: 'cornet',
                        options: {
                          disabled: true,
                          decimalSeparator: '.',
                          size: 'default',
                          step: 1,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'inputNumber',
                        isLeaf: true,
                        icon: 'icon-shuzishuru',
                        key: 'inputNumber_07ae5f366955f600268e50685b27701',
                        label: '座机号',
                        model: 'landlineNo',
                        options: {
                          disabled: true,
                          decimalSeparator: '.',
                          size: 'default',
                          step: 1,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'inputNumber',
                        isLeaf: true,
                        icon: 'icon-shuzishuru',
                        key: 'inputNumber_07ae5f366955f600268e50685b27701',
                        label: '科室号',
                        model: 'deptNo',
                        options: {
                          disabled: true,
                          decimalSeparator: '.',
                          size: 'default',
                          step: 1,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_d3d6c13da4f24a2d5a8eb6a03ddece6',
                        label: '专业',
                        model: 'major',
                        options: {
                          disabled: true,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'select',
                        isLeaf: true,
                        icon: 'icon-xialaxuanze',
                        key: 'select_ba07d3361905b165e18d001222d7033',
                        label: '聘用方式',
                        model: 'engageWay',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: true,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          dataSourceType: 'staticData',
                          dataSource: [
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'select',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_d14e7da6ad55eafa8a8768af7f429aa',
                        label: '编制',
                        model: 'structural',
                        options: {
                          disabled: true,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入',
                          dataSource: [
                            { label: '有', value: '有' },
                            { label: '无', value: '无' }
                          ]
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-xialaxuanze',
                        key: 'select_240b4cffe5468ebbce9975ea93aa663',
                        label: '职称/级别',
                        model: 'professional',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: true,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          dataSourceType: 'staticData',
                          dataSource: [
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-xialaxuanze',
                        key: 'select_0122d6e3e786ae0b8e8c07ed3a87739',
                        label: '部门',
                        model: 'deptName',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: true,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          // dataSourceType: 'staticData',
                          dataSource: [
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: true,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_a50fb4c089368845289eb58c24ecc52',
                        label: '职务',
                        model: 'duty',
                        options: {
                          disabled: true,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'select',
                        isLeaf: true,
                        icon: 'icon-xialaxuanze',
                        key: 'select_0122d6e3e786ae0b8e8c07ed3a87739',
                        label: '学历',
                        model: 'education',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: true,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          // dataSourceType: 'staticData',
                          dataSource: [
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_a50fb4c089368845289eb58c24ecc52',
                        label: '学位',
                        model: 'degree',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: true,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          // dataSourceType: 'staticData',
                          dataSource: [
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [

                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'datePicker',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_a50fb4c089368845289eb58c24ecc52',
                        label: '首次工作时间',
                        model: 'workDate',
                        options: {
                          disabled: true,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 2,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-xialaxuanze',
                        key: 'select_0122d6e3e786ae0b8e8c07ed3a87739',
                        label: '',
                        model: 'file',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: false,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          // dataSourceType: 'staticData',
                          dataSource: [
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    currentTable: {
      handler (val) {
        if (this.watchShow) {
          this.getData(val.id)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    if (!isEmpty(this.currentTable.id)) this.getData(this.currentTable.id)
    this.watchShow = true
    this.deptList()
    // 聘用
    this.engageWay()
    // 职称
    this.professional()
    // 学位
    this.degree()
    // 学历
    this.education()
  },

  methods: {
    getData (val) {
      api.get(val).then(res => {
        this.userData = res.data
        this.reportId = res.data.id
        this.selectTreeValue = this.userData.deptId
        this.selectTreeLable = this.userData.deptName
        this.$refs.formBuilder.setFieldsValue(res.data)
        this.$nextTick(() => {
          this.$refs.file.getFileList(res.data.id, res.data.fileBusinessType)
          this.ageCalculation(res.data.birthday)
        })
      })
    },
    disableDate (current) {
      return current > moment().add(-1, 'day')
    },
    submit () {
      this.$refs.formBuilder.form.validateFields(async (errors, values) => {
        if (!errors) {
          const report = values
          report.deptName = this.deptData.filter(res => { return res.value === report.deptId })[0].label
          api.save(report).then(res => {
            this.$message.success(res.message)
            this.getList()
            this.changeValue('addShow', false)
            const obj = {}
            Object.keys(this.$refs.formBuilder.getFieldsValue()).forEach(res => {
              obj[res] = ''
            })
            this.$refs.formBuilder.setFieldsValue(obj)
          })
        } else {
          this.$message.warning('请填写完整表单信息')
        }
      })
    },
    async professional () {
      await api.dictData({ dicKind: 'staff_professional' }).then(res => {
        const options = res.data.map(res => { return { label: res.dicDesc, value: res.dicCode } })
        setAttribute(this.$refs.formBuilder.formBuilder.childrenNodes, 'professional', 'dataSource', options)
      })
    },
    async degree () {
      await api.dictData({ dicKind: 'staff_degree' }).then(res => {
        const options = res.data.map(res => { return { label: res.dicDesc, value: res.dicCode } })
        setAttribute(this.$refs.formBuilder.formBuilder.childrenNodes, 'degree', 'dataSource', options)
      })
    },
    async education () {
      await api.dictData({ dicKind: 'staff_education' }).then(res => {
        const options = res.data.map(res => { return { label: res.dicDesc, value: res.dicCode } })
        setAttribute(this.$refs.formBuilder.formBuilder.childrenNodes, 'education', 'dataSource', options)
      })
    },
    async engageWay () {
      await api.dictData({ dicKind: 'staff_engage_way' }).then(res => {
        const options = res.data.map(res => { return { label: res.dicDesc, value: res.dicCode } })
        setAttribute(this.$refs.formBuilder.formBuilder.childrenNodes, 'engageWay', 'dataSource', options)
      })
    },
    async deptList () {
      await api.deptList().then(res => {
        this.deptData = res.data.map(res => { return { label: res.deptName, value: res.id } })
        setAttribute(this.$refs.formBuilder.formBuilder.childrenNodes, 'deptId', 'dataSource', this.deptData)
      })
    }
  }
}
</script>
